import {
  Box,
  Card,
  CardHeader,
  Container,
  Divider,
  IconButton,
  LinearProgress,
  Stack,
  Typography,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { getEmployeeDidacDetails, triggerEmployeeSync } from 'src/api/employee';
import EmptyContent from 'src/components/empty-content';
import Iconify from 'src/components/iconify';
import Label from 'src/components/label';
import { useSnackbar } from 'src/components/snackbar';
import { EmployeeSyncArea, IEmployeeDidacItem } from 'src/types/employee';

type Props = {
  employeeId: string | undefined;
  employeeName: string | undefined;
  didacId: number | null | undefined;
};

type DetailsContainerProp = {
  data: IEmployeeDidacItem | null;
};

function DetailsContainer({ data }: DetailsContainerProp) {
  return (
    <>
      {data ? (
        <Stack
          spacing={2}
          alignItems={{ md: 'flex-start' }}
          direction={{ xs: 'column' }}
          sx={{
            p: 2,
            pt: 0,
            fontSize: 12,
          }}
        >
          <Stack>
            <Box sx={{ color: 'text.disabled' }}>User Id</Box>
            {data?.userId}
          </Stack>
          <Stack>
            <Box sx={{ color: 'text.disabled' }}>Foreign System Key</Box>
            {data?.foreignSystemKey}
          </Stack>
          <Stack>
            <Box sx={{ color: 'text.disabled' }}>Logon Name</Box>
            {data?.logonName}
          </Stack>
          <Stack>
            <Box sx={{ color: 'text.disabled' }}>Email</Box>
            {data?.email}
          </Stack>
          <Stack>
            <Box sx={{ color: 'text.disabled' }}>First Name</Box>
            {data?.firstName}
          </Stack>
          <Stack>
            <Box sx={{ color: 'text.disabled' }}>Last Name</Box>
            {data?.lastName}
          </Stack>
          <Stack>
            <Box sx={{ color: 'text.disabled' }}>Phone Number</Box>
            {data?.phoneNumber}
          </Stack>
          <>
            {data?.memberGroups?.map((item, index) => (
              <Stack
                spacing={2}
                alignItems={{ md: 'flex-start' }}
                direction={{ xs: 'column' }}
                key={`mb${index}`}
                sx={{
                  width: '100%',
                }}
              >
                <Divider
                  sx={{ borderStyle: 'dotted', mt: 0, mb: 0, borderWidth: 1, width: '100%' }}
                />
                <Stack>
                  <Box sx={{ color: 'text.disabled' }}>Group Id</Box>
                  {item.groupId}
                </Stack>
                <Stack>
                  <Box sx={{ color: 'text.disabled' }}>Group Name</Box>
                  {item.groupName}
                </Stack>
                <Stack>
                  <Box sx={{ color: 'text.disabled' }}>Group Category</Box>
                  {item.groupCategoryId}
                </Stack>
              </Stack>
            ))}
          </>
          <>
            {data?.memberGroups?.length === 0 ? (
              <>
                <Divider
                  sx={{ borderStyle: 'dotted', mt: 0, mb: 0, borderWidth: 1, width: '100%' }}
                />
                <Box sx={{ color: 'text.disabled' }}>No member group assigned</Box>
              </>
            ) : (
              <></>
            )}
          </>
        </Stack>
      ) : (
        <EmptyContent
          title="No Data"
          sx={{
            pt: 3,
            pb: 6,
          }}
        />
      )}
    </>
  );
}

function DidacStatusBadge({ data }: DetailsContainerProp) {
  if (!data) {
    return null;
  }

  if (data.disabled) {
    return (
      <Label variant="soft" color="error">
        Disabled
      </Label>
    );
  }
  return (
    <Label variant="soft" color="success">
      Enabled
    </Label>
  );
}

export default function EmployeeDetailsDidac({ didacId, employeeId, employeeName }: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [didacData, setDidacData] = useState<IEmployeeDidacItem | null>(null);

  const loadData = useCallback(() => {
    if (!didacId) {
      return;
    }

    setLoading(true);
    getEmployeeDidacDetails(didacId)
      .then((response) => {
        setDidacData(response.data);
      })
      .catch((err) => {
        console.log(err);
        setDidacData(null);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [didacId]);

  const triggerSync = useCallback(() => {
    if (!employeeId) {
      return;
    }

    setLoading(true);
    enqueueSnackbar(`Didac sync triggered for ${employeeName}`);
    triggerEmployeeSync(employeeId, [EmployeeSyncArea.Didac])
      .then(() => {
        loadData();
      })
      .catch((err) => {
        enqueueSnackbar(`Didac sync failed for ${employeeName}`, {
          variant: 'error',
        });
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [enqueueSnackbar, loadData, employeeId, employeeName]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <Card>
      <CardHeader
        title={
          <Stack spacing={1} direction="row" alignItems="center">
            <Typography variant="h6">Didac</Typography>
            <DidacStatusBadge data={didacData} />
          </Stack>
        }
        action={
          <Stack spacing={0} direction="row">
            <IconButton title="Trigger sync" onClick={() => triggerSync()}>
              <Iconify icon="mdi:account-sync" />
            </IconButton>

            <IconButton title="Refresh data" onClick={() => loadData()} disabled={!didacId}>
              <Iconify icon="eva:refresh-outline" />
            </IconButton>
          </Stack>
        }
      />
      <Divider sx={{ borderStyle: 'solid', mt: 2, mb: 2 }} />
      {loading ? (
        <Container maxWidth="lg">
          <LinearProgress key="primary" color="primary" sx={{ mb: 2, width: 1 }} />
        </Container>
      ) : (
        <DetailsContainer data={didacData} />
      )}
    </Card>
  );
}
