import {
  Box,
  Card,
  CardHeader,
  Container,
  Divider,
  IconButton,
  LinearProgress,
  Stack,
  Typography,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { getEmployeeFamlyDetails, triggerEmployeeSync } from 'src/api/employee';
import EmptyContent from 'src/components/empty-content';
import Iconify from 'src/components/iconify';
import Label from 'src/components/label';
import { useSnackbar } from 'src/components/snackbar';
import { EmployeeSyncArea, IEmployeeFamlyItem } from 'src/types/employee';

type Props = {
  employeeId: string | undefined;
  employeeName: string | undefined;
  famlyId: string | null | undefined;
};

type DetailsContainerProp = {
  data: IEmployeeFamlyItem | null;
};

function DetailsContainer({ data }: DetailsContainerProp) {
  return (
    <>
      {data ? (
        <Stack
          spacing={2}
          alignItems={{ md: 'flex-start' }}
          direction={{ xs: 'column' }}
          sx={{
            p: 2,
            pt: 0,
            fontSize: 12,
          }}
        >
          <Stack>
            <Box sx={{ color: 'text.disabled' }}>Employee Id</Box>
            {data?.id}
          </Stack>
          <Stack>
            <Box sx={{ color: 'text.disabled' }}>Employee Name</Box>
            {data?.fullName}
          </Stack>
          <Stack>
            <Box sx={{ color: 'text.disabled' }}>Employee Email</Box>
            {data?.email}
          </Stack>
          <Stack>
            <Box sx={{ color: 'text.disabled' }}>Site Id</Box>
            {data?.siteId}
          </Stack>
          <Stack>
            <Box sx={{ color: 'text.disabled' }}>Site Name</Box>
            {data?.siteName}
          </Stack>
          <Stack>
            <Box sx={{ color: 'text.disabled' }}>Room Id</Box>
            {data?.groupId}
          </Stack>
          <Stack>
            <Box sx={{ color: 'text.disabled' }}>Room Name</Box>
            {data?.groupName}
          </Stack>
          <Stack>
            <Box sx={{ color: 'text.disabled' }}>Role Id</Box>
            {data?.roleId}
          </Stack>
          <Stack>
            <Box sx={{ color: 'text.disabled' }}>Role Name</Box>
            {data?.title}
          </Stack>
        </Stack>
      ) : (
        <EmptyContent
          title="No Data"
          sx={{
            pt: 3,
            pb: 6,
          }}
        />
      )}
    </>
  );
}

function FamlyStatusBadge({ data }: DetailsContainerProp) {
  if (!data) {
    return null;
  }

  if (data.roleId) {
    return (
      <Label variant="soft" color="success">
        Enabled
      </Label>
    );
  }
  return (
    <Label variant="soft" color="error">
      Disabled
    </Label>
  );
}

export default function EmployeeDetailsFamly({ famlyId, employeeId, employeeName }: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [famlyData, setFamlyData] = useState<IEmployeeFamlyItem | null>(null);

  const loadData = useCallback(() => {
    if (!famlyId) {
      return;
    }

    if (!employeeId) {
      return;
    }

    setLoading(true);
    getEmployeeFamlyDetails(employeeId)
      .then((response) => {
        setFamlyData(response.data);
      })
      .catch((err) => {
        console.log(err);
        setFamlyData(null);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [famlyId, employeeId]);

  const triggerSync = useCallback(() => {
    if (!employeeId) {
      return;
    }

    setLoading(true);
    enqueueSnackbar(`Famly sync triggered for ${employeeName}`);
    triggerEmployeeSync(employeeId, [EmployeeSyncArea.Famly])
      .then((r) => {
        enqueueSnackbar(`Famly sync finished for ${employeeName}`, {
          variant: 'success',
        });
        loadData();
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar(`Famly sync failed due to ${err[0]?.errorKey || 'unknown error'}`, {
          variant: 'error',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [enqueueSnackbar, loadData, employeeId, employeeName]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <Card>
      <CardHeader
        title={
          <Stack spacing={1} direction="row" alignItems="center">
            <Typography variant="h6">Famly</Typography>
            <FamlyStatusBadge data={famlyData} />
          </Stack>
        }
        action={
          <Stack spacing={0} direction="row">
            <IconButton title="Trigger sync" onClick={() => triggerSync()}>
              <Iconify icon="mdi:account-sync" />
            </IconButton>

            <IconButton title="Refresh data" onClick={() => loadData()} disabled={!famlyId}>
              <Iconify icon="eva:refresh-outline" />
            </IconButton>
          </Stack>
        }
      />
      <Divider sx={{ borderStyle: 'solid', mt: 2, mb: 2 }} />
      {loading ? (
        <Container maxWidth="lg">
          <LinearProgress key="primary" color="primary" sx={{ mb: 2, width: 1 }} />
        </Container>
      ) : (
        <DetailsContainer data={famlyData} />
      )}
    </Card>
  );
}
