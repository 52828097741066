import {
  Box,
  Card,
  CardHeader,
  Container,
  Divider,
  IconButton,
  LinearProgress,
  Stack,
  Typography,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { getEmployeeEvolutionDetails, triggerEmployeeSync } from 'src/api/employee';
import Iconify from 'src/components/iconify';
import Label from 'src/components/label';
import { useSnackbar } from 'src/components/snackbar';
import { EmployeeSyncArea, IEmployeeEvolutionItem } from 'src/types/employee';

type Props = {
  employeeId: string | undefined;
  employeeName: string | undefined;
  evolutionId: number | null | undefined;
};

type BadgeProps = {
  evoData: IEmployeeEvolutionItem | undefined;
};

function EvolutionStatusBadge({ evoData }: BadgeProps) {
  if (!evoData) {
    return null;
  }

  const { isActive } = evoData;
  if (!isActive) {
    return (
      <Label variant="soft" color="error">
        Disabled
      </Label>
    );
  }
  return (
    <Label variant="soft" color="success">
      Enabled
    </Label>
  );
}

export default function EmployeeDetailsEvolution({ evolutionId, employeeId, employeeName }: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<IEmployeeEvolutionItem>();

  const loadData = useCallback(() => {
    if (!evolutionId) {
      return;
    }

    setLoading(true);
    getEmployeeEvolutionDetails(evolutionId).then((response) => {
      setData(response.data);
      setLoading(false);
    });
  }, [evolutionId]);

  const triggerSync = useCallback(() => {
    if (!employeeId) {
      return;
    }

    setLoading(true);
    enqueueSnackbar(`Evolution sync triggered for ${employeeName}`);
    triggerEmployeeSync(employeeId, [EmployeeSyncArea.Evolution])
      .then(() => {
        loadData();
      })
      .catch((err) => {
        enqueueSnackbar(`Evolution sync failed for ${employeeName}`, {
          variant: 'error',
        });
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [enqueueSnackbar, loadData, employeeId, employeeName]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <Card>
      <CardHeader
        title={
          <Stack spacing={1} direction="row" alignItems="center">
            <Typography variant="h6">Evolution</Typography>
            <EvolutionStatusBadge evoData={data} />
          </Stack>
        }
        action={
          <Stack spacing={0} direction="row">
            <IconButton title="Trigger sync" onClick={() => triggerSync()}>
              <Iconify icon="mdi:account-sync" />
            </IconButton>

            <IconButton title="Refresh data" onClick={() => loadData()}>
              <Iconify icon="eva:refresh-outline" />
            </IconButton>
          </Stack>
        }
      />
      <Divider sx={{ borderStyle: 'solid', mt: 2, mb: 2 }} />
      {loading ? (
        <Container maxWidth="lg">
          <LinearProgress key="primary" color="primary" sx={{ mb: 2, width: 1 }} />
        </Container>
      ) : (
        <Stack
          spacing={2}
          alignItems={{ md: 'flex-start' }}
          direction={{ xs: 'column' }}
          sx={{
            p: 2,
            pt: 0,
            fontSize: 12,
          }}
        >
          <Stack>
            <Box sx={{ color: 'text.disabled' }}>User Id</Box>
            {data?.id}
          </Stack>
          <Stack>
            <Box sx={{ color: 'text.disabled' }}>User External Id</Box>
            {data?.idExt}
          </Stack>
          <Stack>
            <Box sx={{ color: 'text.disabled' }}>Evolution User Id</Box>
            {data?.userId}
          </Stack>
          <Stack>
            <Box sx={{ color: 'text.disabled' }}>Email</Box>
            {data?.email}
          </Stack>
          <Stack>
            <Box sx={{ color: 'text.disabled' }}>First Name</Box>
            {data?.firstName}
          </Stack>
          <Stack>
            <Box sx={{ color: 'text.disabled' }}>Last Name</Box>
            {data?.lastName}
          </Stack>
          <Stack>
            <Box sx={{ color: 'text.disabled' }}>Birth Date</Box>
            {data?.birthDate}
          </Stack>
          <Stack>
            <Box sx={{ color: 'text.disabled' }}>Employed Since</Box>
            {data?.employedSince}
          </Stack>
          <Stack>
            <Box sx={{ color: 'text.disabled' }}>Employed Until</Box>
            {data?.employedUntil}
          </Stack>
          <Stack>
            <Box sx={{ color: 'text.disabled' }}>Home Organization Unit</Box>
            {data?.homeOrgUnit}
          </Stack>
          <Stack>
            <Box sx={{ color: 'text.disabled' }}>Work Description</Box>
            {data?.workDescription}
          </Stack>
          <Stack>
            <Box sx={{ color: 'text.disabled' }}>Employee Type</Box>
            {data?.employeeType}
          </Stack>
          <Stack>
            <Box sx={{ color: 'text.disabled' }}>Main Work Place</Box>
            {data?.mainWorkPlace}
          </Stack>
          <Stack>
            <Box sx={{ color: 'text.disabled' }}>Leader</Box>
            {data?.leader}
          </Stack>
          <Stack>
            <Box sx={{ color: 'text.disabled' }}>User Type</Box>
            {data?.userType}
          </Stack>
          <Stack>
            <Box sx={{ color: 'text.disabled' }}>Company Role</Box>
            {data?.companyRole}
          </Stack>
          <Stack>
            <Box sx={{ color: 'text.disabled' }}>Mobile Phone</Box>
            {data?.mobilePhone}
          </Stack>
          <Stack>
            <Box sx={{ color: 'text.disabled' }}>Modified On</Box>
            {data?.modifiedOn}
          </Stack>
          <Stack>
            <Box sx={{ color: 'text.disabled' }}>Last Confirmed At</Box>
            {data?.lastConfirmedAt}
          </Stack>
        </Stack>
      )}
    </Card>
  );
}
