import {
  Box,
  Card,
  CardHeader,
  Container,
  Divider,
  IconButton,
  LinearProgress,
  Stack,
  Typography,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { getEmployeeAadDetails, triggerEmployeeSync } from 'src/api/employee';
import EmptyContent from 'src/components/empty-content';
import Iconify from 'src/components/iconify';
import Label from 'src/components/label';
import { useSnackbar } from 'src/components/snackbar';
import { EmployeeSyncArea, IEmployeeAadItem } from 'src/types/employee';

type Props = {
  employeeId: string | undefined;
  employeeName: string | undefined;
  aadId: string | null | undefined;
};

type DetailsContainerProp = {
  data: IEmployeeAadItem | null;
};

function DetailsContainer({ data }: DetailsContainerProp) {
  return (
    <>
      {data ? (
        <Stack
          spacing={2}
          alignItems={{ md: 'flex-start' }}
          direction={{ xs: 'column' }}
          sx={{
            p: 2,
            pt: 0,
            fontSize: 12,
          }}
        >
          <Stack>
            <Box sx={{ color: 'text.disabled' }}>User Id</Box>
            {data?.id}
          </Stack>
          <Stack>
            <Box sx={{ color: 'text.disabled' }}>UPN</Box>
            {data?.userPrincipalName}
          </Stack>
          <Stack>
            <Box sx={{ color: 'text.disabled' }}>Company Name</Box>
            {data?.companyName}
          </Stack>
          <Stack>
            <Box sx={{ color: 'text.disabled' }}>Department</Box>
            {data?.department}
          </Stack>
          <Stack>
            <Box sx={{ color: 'text.disabled' }}>Employee Id</Box>
            {data?.employeeId}
          </Stack>
          <Stack>
            <Box sx={{ color: 'text.disabled' }}>Employee Type</Box>
            {data?.employeeType}
          </Stack>
          <Stack>
            <Box sx={{ color: 'text.disabled' }}>Given Name</Box>
            {data?.givenName}
          </Stack>
          <Stack>
            <Box sx={{ color: 'text.disabled' }}>Sur Name</Box>
            {data?.surname}
          </Stack>
          <Stack>
            <Box sx={{ color: 'text.disabled' }}>Job Title</Box>
            {data?.jobTitle}
          </Stack>
          <Stack>
            <Box sx={{ color: 'text.disabled' }}>Sur Name</Box>
            {data?.surname}
          </Stack>
          <Stack>
            <Box sx={{ color: 'text.disabled' }}>Office Location</Box>
            {data?.officeLocation}
          </Stack>
          <Stack>
            <Box sx={{ color: 'text.disabled' }}>Birthday</Box>
            {data?.birthday}
          </Stack>
          <Stack>
            <Box sx={{ color: 'text.disabled' }}>Hire Date</Box>
            {data?.hireDate}
          </Stack>
          <Stack>
            <Box sx={{ color: 'text.disabled' }}>Mobile Phone</Box>
            {data?.mobilePhone}
          </Stack>
          <Stack>
            <Box sx={{ color: 'text.disabled' }}>Ext Attrib 1</Box>
            {data?.extAttribute1}
          </Stack>
          <Stack>
            <Box sx={{ color: 'text.disabled' }}>Ext Attrib 2</Box>
            {data?.extAttribute2}
          </Stack>
          <Stack>
            <Box sx={{ color: 'text.disabled' }}>Ext Attrib 3</Box>
            {data?.extAttribute3}
          </Stack>
          <Stack>
            <Box sx={{ color: 'text.disabled' }}>Ext Attrib 4</Box>
            {data?.extAttribute4}
          </Stack>
          <Stack>
            <Box sx={{ color: 'text.disabled' }}>Ext Attrib 5</Box>
            {data?.extAttribute5}
          </Stack>
          <Stack>
            <Box sx={{ color: 'text.disabled' }}>Ext Attrib 6</Box>
            {data?.extAttribute6}
          </Stack>
          <Stack>
            <Box sx={{ color: 'text.disabled' }}>Ext Attrib 7</Box>
            {data?.extAttribute7}
          </Stack>
          <Stack>
            <Box sx={{ color: 'text.disabled' }}>Ext Attrib 8</Box>
            {data?.extAttribute8}
          </Stack>
          <Stack>
            <Box sx={{ color: 'text.disabled' }}>Ext Attrib 9</Box>
            {data?.extAttribute9}
          </Stack>
          <Stack>
            <Box sx={{ color: 'text.disabled' }}>Ext Attrib 10</Box>
            {data?.extAttribute10}
          </Stack>
          <Stack>
            <Box sx={{ color: 'text.disabled' }}>Ext Attrib 11</Box>
            {data?.extAttribute11}
          </Stack>
          <Stack>
            <Box sx={{ color: 'text.disabled' }}>Ext Attrib 12</Box>
            {data?.extAttribute12}
          </Stack>
          <Stack>
            <Box sx={{ color: 'text.disabled' }}>Ext Attrib 13</Box>
            {data?.extAttribute13}
          </Stack>
          <Stack>
            <Box sx={{ color: 'text.disabled' }}>Ext Attrib 14</Box>
            {data?.extAttribute14}
          </Stack>
          <Stack>
            <Box sx={{ color: 'text.disabled' }}>Ext Attrib 15</Box>
            {data?.extAttribute15}
          </Stack>
        </Stack>
      ) : (
        <EmptyContent
          title="No Data"
          sx={{
            pt: 3,
            pb: 6,
          }}
        />
      )}
    </>
  );
}

function AadStatusBadge({ data }: DetailsContainerProp) {
  if (!data) {
    return null;
  }

  if (data.accountEnabled) {
    return (
      <Label variant="soft" color="success">
        Enabled
      </Label>
    );
  }
  return (
    <Label variant="soft" color="error">
      Disabled
    </Label>
  );
}

export default function EmployeeDetailsAad({ aadId, employeeId, employeeName }: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [aadData, setAadData] = useState<IEmployeeAadItem | null>(null);

  const loadData = useCallback(() => {
    if (!aadId) {
      return;
    }

    setLoading(true);
    getEmployeeAadDetails(aadId)
      .then((response) => {
        setAadData(response.data);
      })
      .catch((err) => {
        console.log(err);
        setAadData(null);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [aadId]);

  const triggerSync = useCallback(() => {
    if (!employeeId) {
      return;
    }

    setLoading(true);
    enqueueSnackbar(`${employeeName} - sync to AAD triggered`);
    triggerEmployeeSync(employeeId, [EmployeeSyncArea.ActiveDirectory])
      .then(() => {
        loadData();
      })
      .catch((err) => {
        enqueueSnackbar(`${employeeName} - sync to AAD failed`, {
          variant: 'error',
        });
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [enqueueSnackbar, loadData, employeeId, employeeName]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <Card>
      <CardHeader
        title={
          <Stack spacing={1} direction="row" alignItems="center">
            <Typography variant="h6">AD</Typography>
            <AadStatusBadge data={aadData} />
          </Stack>
        }
        action={
          <Stack spacing={0} direction="row">
            <IconButton title="Trigger sync" onClick={() => triggerSync()}>
              <Iconify icon="mdi:account-sync" />
            </IconButton>

            <IconButton title="Refresh data" onClick={() => loadData()} disabled={!aadId}>
              <Iconify icon="eva:refresh-outline" />
            </IconButton>
          </Stack>
        }
      />
      <Divider sx={{ borderStyle: 'solid', mt: 2, mb: 2 }} />
      {loading ? (
        <Container maxWidth="lg">
          <LinearProgress key="primary" color="primary" sx={{ mb: 2, width: 1 }} />
        </Container>
      ) : (
        <DetailsContainer data={aadData} />
      )}
    </Card>
  );
}
