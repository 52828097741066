import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSettingsContext } from 'src/components/settings';
import { EmployeeSyncArea, IEmployeeItem } from 'src/types/employee';
import { getEmployeeDetails, triggerEmployeeSync } from 'src/api/employee';
import { LinearProgress, Container, Grid, Tabs, Tab } from '@mui/material';
import { useSnackbar } from 'src/components/snackbar';
import Iconify from 'src/components/iconify';
import EmployeeDetailsToolbar from '../employee-details-toolbar';
import EmployeeDetailsFamly from '../employee-details-famly';
import EmployeeDetailsEik from '../employee-details-eik';
import EmployeeDetailsDidac from '../employee-details-didac';
import EmployeeDetailsEvolution from '../employee-details-evolution';
import EmployeeDetailsAad from '../employee-details-aad';
import EmployeeDetailsSyncDebug from '../employee-details-sync-debug';

const TABS = [
  {
    value: 'data',
    label: 'Data',
    icon: <Iconify icon="solar:user-id-bold" width={24} />,
  },
  {
    value: 'history',
    label: 'History',
    icon: <Iconify icon="solar:bill-list-bold" width={24} />,
  },
  {
    value: 'debug',
    label: 'Debug',
    icon: <Iconify icon="solar:bill-list-bold" width={24} />,
  },
];

type Props = {
  id: string;
};

export default function EmployeeDetailsView({ id }: Props) {
  const settings = useSettingsContext();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);

  const [currentTab, setCurrentTab] = useState('data');

  const [employee, setEmployee] = useState<IEmployeeItem>();
  const [employeeName, setEmployeeName] = useState<string>();

  const handleChangeTab = useCallback((event: React.SyntheticEvent, newValue: string) => {
    setCurrentTab(newValue);
  }, []);

  const loadData = useCallback(() => {
    setLoading(true);
    getEmployeeDetails(id).then((response) => {
      setEmployee(response.data);
      setEmployeeName(`${response.data.firstName} ${response.data.lastName}`);
      setLoading(false);
    });
  }, [id]);

  const triggerSync = useCallback(() => {
    if (!id) {
      return;
    }

    setLoading(true);
    enqueueSnackbar(`${employeeName} - full sync triggered`);
    triggerEmployeeSync(id, [
      EmployeeSyncArea.ActiveDirectory,
      EmployeeSyncArea.Didac,
      EmployeeSyncArea.EIK,
      EmployeeSyncArea.Famly,
      EmployeeSyncArea.XLedger,
    ])
      .then((response) => {
        loadData();
      })
      .catch((err) => {
        enqueueSnackbar(`${employeeName} - full sync failed`, {
          variant: 'error',
        });
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [enqueueSnackbar, loadData, id, employeeName]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  if (loading) {
    return (
      <Container maxWidth={settings.themeStretch ? false : 'lg'}>
        <LinearProgress key="primary" color="primary" sx={{ mb: 2, width: 1 }} />
      </Container>
    );
  }

  return (
    <>
      <Helmet>
        <title>{employeeName}</title>
      </Helmet>

      <Container maxWidth={settings.themeStretch ? false : 'lg'}>
        <EmployeeDetailsToolbar
          name={`${employee?.firstName} ${employee?.lastName}`}
          lastSyncDate={employee?.lastSyncDate}
          onReload={loadData}
          onSync={triggerSync}
        />

        <Tabs value={currentTab} onChange={handleChangeTab}>
          {TABS.map((tab) => (
            <Tab key={tab.value} label={tab.label} icon={tab.icon} value={tab.value} />
          ))}
        </Tabs>

        {currentTab === 'data' && (
          <Grid container spacing={3}>
            <Grid xs={12} md={2} item>
              <EmployeeDetailsEvolution
                evolutionId={employee?.evoId}
                employeeId={employee?.id}
                employeeName={employeeName}
              />
            </Grid>

            <Grid xs={12} md={2} item>
              <EmployeeDetailsAad
                aadId={employee?.aadId}
                employeeId={employee?.id}
                employeeName={employeeName}
              />
            </Grid>

            <Grid xs={12} md={2} item>
              <EmployeeDetailsFamly
                famlyId={employee?.famlyId}
                employeeId={employee?.id}
                employeeName={employeeName}
              />
            </Grid>

            <Grid xs={12} md={2} item>
              <EmployeeDetailsEik
                eikId={employee?.eikId}
                employeeId={employee?.id}
                employeeName={employeeName}
              />
            </Grid>

            <Grid xs={12} md={2} item>
              <EmployeeDetailsDidac
                didacId={employee?.didacId}
                employeeId={employee?.id}
                employeeName={employeeName}
              />
            </Grid>
          </Grid>
        )}

        {currentTab === 'history' && (
          <Grid container spacing={3}>
            <Grid xs={12} md={2} item>
              <h5>Sync orcherstrations</h5>
            </Grid>
          </Grid>
        )}

        {currentTab === 'debug' && <EmployeeDetailsSyncDebug id={id} />}
      </Container>
    </>
  );
}
